import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../Contexts/ApiContext';
import { Box } from '@material-ui/core';
import GastronautPayment from './GastronautPayment';
import { LanguageContext } from '../Contexts/LanguageContext';

const PaymentPage = ({ match, history }) => {
  const { state } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);
  const { reservationId } = match.params;

  useEffect(() => {
    if (!state.payableItemId) {
      history.push(`/${reservationId}`);
    }
  }, [state]);

  return (
    <Box>
      <GastronautPayment
        restaurantId={state.id}
        payableItemId={state.payableItemId}
        returnUrl={window.location.href}
        language={language}
        requiredFields={['last_name', 'phone', 'email']}
      />
    </Box>
  );
};

export default PaymentPage;
