import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../Contexts/ApiContext';
import { Box, CircularProgress } from '@material-ui/core';

const LoadingScreen = () => {
  const { state, error } = useContext(ApiContext);

  return (
    <Box
      className="loadingContainer"
      style={{ display: state.loading && !error ? 'flex' : 'none' }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
