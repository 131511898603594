import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../Contexts/ApiContext';
import { Box, Typography } from '@material-ui/core';
import GastronautPayment from './GastronautPayment';
import { LanguageContext } from '../Contexts/LanguageContext';

const translations = {
  de: {
    title: 'Es ist ein Fehler aufgetreten!'
  },
  en: {
    title: 'An Error occured'
  }
};

const ErrorPage = ({ match, history }) => {
  const { error } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);
  const { reservationId } = match.params;

  useEffect(() => {
    if (!error) {
      history.push(`/${reservationId}`);
    }
  }, [error]);

  const translation = translations[language];

  return (
    <Box>
      <Typography variant="h4">{translation.title}</Typography>
      <Typography variant="subtitle">{error}</Typography>
    </Box>
  );
};

export default ErrorPage;
