import React, { createContext, useState, useEffect } from 'react';
import { server } from './ApiContext';

export const LanguageContext = createContext();

const LanguageContextProvider = ({ production, children }) => {
  const [texts, setTexts] = useState(false);
  const [language, setlanguage] = useState(
    navigator.language.includes('de') ? 'de' : 'en'
  );

  const getTexts = language => {
    server
      .get(`/language/ticketPage/${language}`)
      .then(function(response) {
        // handle success
        setTexts(response.data);
      })
      .catch(function(error) {
        // handle error
        console.error(error);
        setTexts(false);
      });
  };

  useEffect(() => {
    getTexts(language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ setlanguage, language, texts }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
