import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ApiContext } from '../Contexts/ApiContext';
import {
  Typography,
  Box,
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';
import { LanguageContext } from '../Contexts/LanguageContext';
import OptionPicker from './OptionPicker';
import AdditionalPicker from './AdditionalPicker';
import styles from './main.module.css';
import { stringifyAmount, stringifyDate } from '../Helper';
import VoucherCode from './VoucherCode';

const Footer = () => {
  const [loading, setloading] = useState(false);
  const { total = 0, createPayableItem, state, options } = useContext(
    ApiContext
  );
  const { texts } = useContext(LanguageContext);

  const isDisabled = useMemo(() => {
    let totalAmount = state.guests;

    let currentAmount = Object.keys(options).reduce(
      (acc, cV) => acc + options[cV],
      0
    );

    return totalAmount > currentAmount;
  }, [state, options]);

  const handleSubmit = async () => {
    try {
      setloading(true);
      await createPayableItem();
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <Box className={styles.ticketPageFooterContainer}>
      <Typography style={{ flexGrow: 1, marginRight: 5 }}>
        <strong>{texts.sum}</strong> {texts.vatText}
      </Typography>
      <Button
        disabled={isDisabled}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ minWidth: 150 }}
      >
        {!loading ? (
          `${texts.payNow} ${stringifyAmount(total)}`
        ) : (
          <CircularProgress size="18px" />
        )}
      </Button>
    </Box>
  );
};

const TicketPage = ({ match, history }) => {
  const { getInitialData, state, error } = useContext(ApiContext);
  const { language, texts } = useContext(LanguageContext);
  const { reservationId } = match.params;

  useEffect(() => {
    getInitialData(reservationId);
  }, [reservationId]);

  useEffect(() => {
    if (state.payableItemId) {
      history.push(`/${reservationId}/payment`);
    }
  }, [state.payableItemId]);

  useEffect(() => {
    if (error) {
      history.push(`/${reservationId}/error`);
    }
  }, [error]);

  console.log({ state, language, texts });

  if (!state.ticket) return <></>;

  return (
    <>
      <Grid container spacing={1} className={styles.container}>
        <Grid item sm={12} md={8}>
          <Typography className={styles.subTitle}>{texts.step1}</Typography>

          {state.ticket.options &&
            state.ticket.options.map(option => <OptionPicker data={option} />)}

          <Typography className={styles.subTitle}>{texts.step2}</Typography>

          {state.ticket.additional &&
            state.ticket.additional.map(additional => (
              <AdditionalPicker data={additional} />
            ))}
        </Grid>

        <Grid item sm={12} md={4}>
          <small>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginTop: 10
              }}
            >
              <span>{texts.eventLabel}:</span>

              <span>{state.ticket.title[language]}</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10
              }}
            >
              <span>{texts.dateLabel}:</span>

              <span>
                {new Date(state.date).toLocaleDateString(language)} {state.time}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10
              }}
            >
              <span>{texts.guestsLabel}:</span>

              <span>
                {state.guests} {texts.persons}
              </span>
            </div>
          </small>

          <VoucherCode />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default TicketPage;
