const stringifyAmount = (amount, currency = 'EUR') => {
  if (amount < 0) {
    amount = 0;
  }

  let macro = Math.floor(amount / 100);

  let micro = amount % 100;

  if (micro < 10) {
    micro = '0' + micro;
  }

  if (currency === 'EUR') {
    return `${macro},${micro}€`;
  } else if (currency === 'USD') {
    return `$${macro}.${micro}`;
  } else {
    return `${macro},${micro}${currency}`;
  }
};

function stringifyDate(date, isGerman) {
  let weekdays = isGerman
    ? [
        'Sonntag, der ',
        'Montag, der ',
        'Dienstag, der ',
        'Mittwoch, der ',
        'Donnerstag, der ',
        'Freitag, der ',
        'Samstag, der '
      ]
    : [
        'Sunday, the ',
        'Monday, the ',
        'Tuesday, the ',
        'Wednesday, the ',
        'Thursday, the ',
        'Friday, the ',
        'Saturday, the '
      ];

  let wday = weekdays[new Date(date).getDay()];

  let parts = date.split('-');

  return `${wday}${parts[2]}.${parts[1]}.${parts[0].slice(2)}`;
}

module.exports = { stringifyAmount, stringifyDate };
