import React, { useContext, useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { ApiContext } from '../Contexts/ApiContext';
import { LanguageContext } from '../Contexts/LanguageContext';
import { ArrowForwardIos } from '@material-ui/icons';
import { stringifyAmount } from '../Helper';

const VoucherCode = () => {
  const [state, setstate] = useState({});
  const { getVoucher = () => {} } = useContext(ApiContext);
  const { texts } = useContext(LanguageContext);

  const handleChange = ({ target: { value } }) => {
    setstate({ ...state, value });
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();

      if (state.loading) return;

      setstate({ ...state, loading: true });

      const { valid, amount } = await getVoucher(state.value);

      let message = valid
        ? `${texts.voucherIsValid} ${stringifyAmount(amount)}`
        : texts.voucherInvalid;

      setstate({ ...state, loading: false, error: null, message });
    } catch (error) {
      setstate({ ...state, loading: false, error: error.message });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      style={{ display: 'flex', alignItems: 'flex-end' }}
    >
      <TextField
        value={state.value}
        onChange={handleChange}
        label={texts.voucherLabel}
        fullWidth
        error={!!state.error}
        helperText={state.error ? texts[state.error] : state.message}
      />
      <IconButton type="submit" size="small">
        {!state.loading ? (
          <ArrowForwardIos />
        ) : (
          <CircularProgress size="18px" />
        )}
      </IconButton>
    </Box>
  );
};

export default VoucherCode;
