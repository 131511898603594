import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import styles from './main.module.css';

const AmountPicker = ({
  additionDisabled = false,
  onAddition = () => {},
  onReduction = () => {},
  value = 0,
  style = {}
}) => {
  const buttonStyle = {
    minWidth: 34,
    width: 34,
    borderRadius: 17,
    borderWidth: 2
  };

  return (
    <Box className={styles.picker} style={style}>
      <Button
        onClick={onReduction}
        variant="outlined"
        color="primary"
        size="small"
        style={buttonStyle}
        disabled={value === 0}
      >
        <Remove size="small" />
      </Button>
      <Typography
        style={{ margin: '0 10px', fontWeight: 'bold', fontSize: '1.1rem' }}
      >
        {value}
      </Typography>
      <Button
        onClick={onAddition}
        variant="outlined"
        color="primary"
        size="small"
        style={buttonStyle}
        disabled={additionDisabled}
      >
        <Add size="small" />
      </Button>
    </Box>
  );
};

export default AmountPicker;
