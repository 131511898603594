import React, { useState, useContext } from 'react';
import ApiContextProvider, { ApiContext } from './Contexts/ApiContext';
import LanguageContextProvider from './Contexts/LanguageContext';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TicketPage from './Components/TicketPage';
import PaymentPage from './Components/PaymentPage';
import LoadingScreen from './Components/LoadingScreen';
import InnerHeader from './Components/InnerHeader';
import ErrorPage from './Components/ErrorPage';

const production =
  window.location.hostname.includes('gastronaut') &&
  !window.location.search.includes('debug=true');
const iframe =
  window.self !== window.top || window.location.search.includes('iframe=true');

const darkBackground = window.location.search.includes('darkBackground=true');

const mobile =
  (navigator.userAgent.includes('iPhone') ||
    navigator.userAgent.includes('Android')) &&
  window.innerWidth < 600;

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

const primaryColor = urlParams.get('primaryColor');
const contrastText = urlParams.get('contrastText');
const darkmode = urlParams.get('darkmode');

function Header() {
  const { restaurantData } = useContext(ApiContext);
  return (
    <div className="Header">
      {restaurantData && restaurantData.logo && (
        <a href={restaurantData.link} aria-label="Zurück zur Webseite">
          <img src={restaurantData.logo} style={{ height: 60 }} alt="Logo" />
        </a>
      )}
    </div>
  );
}

function SideImage() {
  const { restaurantData, state } = useContext(ApiContext);

  const image =
    state.ticket && state.ticket.image
      ? state.ticket.image
      : restaurantData.fullScreenImage;

  return (
    <div
      className="SideImage"
      style={{
        backgroundImage: `url('${image}')`
      }}
    ></div>
  );
}

function Footer() {
  return (
    <a
      className={darkBackground ? 'Footer Dark' : 'Footer'}
      href="https://www.gastronaut.ai"
    >
      Copyright 2020 | Powered by Gastronaut
    </a>
  );
}

function App() {
  const [restaurantTheme, setTheme] = useState({
    primaryColor: '#d0d0d0',
    contrastText: '#000000'
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: restaurantTheme.primaryColor,
        contrastText: restaurantTheme.contrastText
      }
    }
  });

  return (
    <div
      className={
        iframe
          ? !darkBackground
            ? 'App Iframe light'
            : 'App Iframe'
          : 'App FullScreen'
      }
    >
      <ApiContextProvider setTheme={setTheme}>
        <LanguageContextProvider>
          <ThemeProvider theme={theme}>
            {!iframe && <Header />}
            {!iframe && <SideImage />}
            <div className="Inner">
              <InnerHeader iframe={iframe} />
              <BrowserRouter>
                <Switch>
                  <Route
                    path="/:reservationId"
                    exact="true"
                    component={TicketPage}
                  />
                  <Route
                    path="/:reservationId/payment"
                    exact="true"
                    component={PaymentPage}
                  />
                  <Route
                    path="/:reservationId/error"
                    exact="true"
                    component={ErrorPage}
                  />
                  <Route path="/" exact="true" component={ErrorPage} />
                </Switch>
              </BrowserRouter>
            </div>
            <Footer />
            {/* <ConfirmationDialog /> */}
            {!production && <div className="testModeFlag">Test Modus</div>}
            <LoadingScreen />
          </ThemeProvider>
        </LanguageContextProvider>
      </ApiContextProvider>
    </div>
  );
}

export default App;
