import React, { useContext } from 'react';
import LanguageToggle from './LanguageToggle';
import { Typography, Box } from '@material-ui/core';
import { ApiContext } from '../Contexts/ApiContext';
import { LanguageContext } from '../Contexts/LanguageContext';
import { Fullscreen } from '@material-ui/icons';

const InnerHeader = ({ iframe, location }) => {
  const { restaurantData, state } = useContext(ApiContext);
  const { texts, language } = useContext(LanguageContext);

  const detailsHeader =
    language === 'de'
      ? `Ihre Reservierung bei ${restaurantData.name}`
      : `Your Reservation at ${restaurantData.name}`;

  return (
    <React.Fragment>
      {texts && restaurantData && (
        <div className="Inner__Header">
          {iframe && restaurantData.logo && (
            <img src={restaurantData.logo} style={{ height: 40 }} />
          )}
          <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {state.ticket && state.ticket.title[language]}
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {iframe && (
              <a
                href={window.location.origin + window.location.pathname}
                target="_blank"
                style={{ marginRight: 10, marginTop: -10, color: '#000' }}
              >
                <Fullscreen />
              </a>
            )}
            <LanguageToggle />
          </Box>
        </div>
      )}
    </React.Fragment>
  );
};

export default InnerHeader;
