import React, { useContext, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from './main.module.css';
import { LanguageContext } from '../Contexts/LanguageContext';
import { ApiContext } from '../Contexts/ApiContext';
import AmountPicker from './AmountPicker';

const OptionPicker = ({ data = {} }) => {
  const { options, setoptions, state } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);

  const { id, image } = data;

  const title = useMemo(() => data.title[language], [data.title, language]);
  const description = useMemo(() => data.description[language], [
    data.description,
    language
  ]);

  const handleReduction = () => {
    let option = options[id];
    if (option === 0) return;
    setoptions({ ...options, [id]: option - 1 });
  };

  const additionDisabled = useMemo(() => {
    let maxAmount = state.guests;

    let currentAmount = Object.keys(options).reduce(
      (acc, cV) => acc + options[cV],
      0
    );

    return maxAmount === currentAmount;
  }, [options]);

  const handleAddition = () => {
    let option = options[id];

    let maxAmount = state.guests;

    let currentAmount = Object.keys(options).reduce(
      (acc, cV) => acc + options[cV],
      0
    );

    if (additionDisabled) return;

    setoptions({ ...options, [id]: option + 1 });
  };

  return (
    <Box key={id} className={styles.optionPicker}>
      {image && <img src={image} alt={title} />}

      <Box style={{ width: '100%' }}>
        <Typography style={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography>{description}</Typography>
        <AmountPicker
          style={{ maxWidth: 100 }}
          onAddition={handleAddition}
          onReduction={handleReduction}
          value={options[id]}
          additionDisabled={additionDisabled}
        />
      </Box>
    </Box>
  );
};

export default OptionPicker;
