import React, { createContext, useState, useMemo } from 'react';
import axios from 'axios';

export const ApiContext = createContext();

export let server = axios.create({
  baseURL: 'https://api.gastronaut.ai/v02/',
  timeout: 10000,
  headers: { 'X-Origin': 'tickets' }
});

const ApiContextProvider = ({ children, setTheme }) => {
  const [state, setstate] = useState({ loading: true });
  const [error, seterror] = useState(null);
  const [options, setoptions] = useState({});
  const [additional, setadditional] = useState({});

  const getInitialData = async reservationId => {
    try {
      const { data } = await server.get(`tickets/res/${reservationId}`);

      setstate({
        ...data,
        reservationId,
        loading: false
      });

      if (data.ticket && data.ticket.options) {
        let optionsObj = data.ticket.options.reduce((acc, cV) => {
          acc[cV.id] = 0;

          return acc;
        }, {});

        setoptions(optionsObj);
      }

      if (data.restaurant && data.restaurant.colorPalette) {
        setTheme(data.restaurant.colorPalette);
      }
    } catch (error) {
      console.error(error.message);
      seterror(error.response ? error.response.data.message : error.message);
    }
  };

  const createPayableItem = async () => {
    try {
      const {
        id: restaurantId,
        reservationId,
        ticket: { id: ticketId },
        voucherId = null
      } = state;

      const body = {
        voucherId,
        restaurantId,
        reservationId,
        ticketId,
        additional,
        options
      };

      const { data } = await server.post(`tickets/payableItem`, body);

      setstate(st => ({ ...st, payableItemId: data.id }));

      return;
    } catch (error) {
      console.error(error.message);
      seterror(error.response ? error.response.data.message : error.message);
    }
  };

  const getVoucher = async voucherId => {
    try {
      const { data } = await server.get(`vouchers/${state.id}/${voucherId}`);

      setstate({
        ...state,
        voucherId,
        discountedValue: data.valid ? data.amount : 0
      });

      return data;
    } catch (error) {
      console.error(error.response.data.message);
      throw error.response.data;
    }
  };

  const total = useMemo(() => {
    if (state.loading) return 0;

    const { discountedValue = 0 } = state;

    const additionalCosts = Object.keys(additional).reduce((acc, cV) => {
      let item = state.ticket.additional.find(a => a.id === cV);

      if (!item) return acc;

      return acc + item.price * additional[cV];
    }, 0);

    const total =
      state.ticket.price * state.guests + additionalCosts - discountedValue;

    return total;
  }, [state, additional]);

  return (
    <ApiContext.Provider
      value={{
        state,
        setstate,
        getInitialData,
        createPayableItem,
        restaurantData: state.restaurant ? state.restaurant : {},
        setoptions,
        options,
        additional,
        setadditional,
        total,
        getVoucher,
        error
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContextProvider;
