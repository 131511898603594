import React, { useContext, useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import styles from './main.module.css';
import { LanguageContext } from '../Contexts/LanguageContext';
import { ApiContext } from '../Contexts/ApiContext';
import AmountPicker from './AmountPicker';
import { stringifyAmount } from '../Helper';

const AdditionalPicker = ({ data = {} }) => {
  const { additional, setadditional, state } = useContext(ApiContext);
  const { language } = useContext(LanguageContext);
  const { id, title = {}, price, image } = data;

  const handleAddition = () => {
    let addition = additional[id] ? additional[id] : 0;

    setadditional({ ...additional, [id]: addition + 1 });
  };

  const handleReduction = () => {
    let addition = additional[id] ? additional[id] : 0;

    if (addition === 0) return;

    setadditional({ ...additional, [id]: addition - 1 });
  };

  const additionDisabled = useMemo(() => {
    let maxAmount = state.guests;

    let currentAmount = Object.keys(additional).reduce(
      (acc, cV) => acc + additional[cV],
      0
    );

    return maxAmount === currentAmount;
  }, [additional]);

  return (
    <Box key={id} className={styles.additionalPicker}>
      {image && <img src={image} alt={title} />}

      <Box style={{ width: '100%' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography style={{ fontWeight: 'bold' }}>
            {title[language]}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {stringifyAmount(price)}
          </Typography>
        </Box>
        <AmountPicker
          style={{ maxWidth: 100 }}
          onAddition={handleAddition}
          onReduction={handleReduction}
          value={additional[id]}
          additionDisabled={additionDisabled}
        />
      </Box>
    </Box>
  );
};

export default AdditionalPicker;
